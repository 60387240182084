import * as React from "react";

export default class Main extends React.Component{
    render(){
        return (
            <div className="model">
            <div className="model-info">
                <h4>Hi, I'm</h4>
                <h1> Alberto Martinez Alberdi</h1>
                <span>Sound and Audio Visual Engineer</span>
                <div className="model-skills">
                    <h2>Summary</h2>
                    <p>
    Nemo enim ipsam volupipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem Nemo enim ipsam...
                    </p>
                </div>
                <div className="model-skills">
                    <h2>Professional</h2>
                    <p>
    Nemo enim ipsam volupipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem Nemo enim ipsam...
                    </p>
                </div>
                <div className="model-skills">
                    <h2>Work Experience</h2>
                    <i>2016 - 2019</i>
                    <span>Freelance Engineer</span>
                    <span>Barcelona Spain</span>
                    <i>2013 - 2016</i>
                    <span>Sound Technician</span>
                    <span>Barcelona, Spain</span>
                </div>
                <div className="model-skills">
                    <h2>Work Awards</h2>
                    <ol id="mytabs" className="nav nav-pills">
                        <li className="active"><a href="#tab1" data-toggle="tab" title="">Present 2002</a></li>
                        <li><a href="#tab2" data-toggle="tab" title="">Present 2005</a></li>
                        <li><a href="#tab3" data-toggle="tab" title="">Present 2008</a></li>
                        <li><a href="#tab4" data-toggle="tab" title="">Present 2010</a></li>
                    </ol>
                    <div id="myTabContent" className="tab-content">
                        <div id="tab1" className="tab-pane fade in active">
                            <ul>
                                <li><img src="images/engineer-sml-01.jpg" alt="" /></li>
                                <li><img src="images/engineer-sml-02.jpg" alt="" /></li>
                                <li><img src="images/engineer-sml-03.jpg" alt="" /></li>
                            </ul>    	
                        </div>
                        <div id="tab2" className="tab-pane fade">
                            <ul>
                                <li><img src="images/engineer-sml-03.jpg" alt="" /></li>
                                <li><img src="images/engineer-sml-02.jpg" alt="" /></li>
                                <li><img src="images/engineer-sml-01.jpg" alt="" /></li>
                            </ul>    	
                        </div>
                        <div id="tab3" className="tab-pane fade">
                            <ul>
                                <li><img src="images/engineer-sml-02.jpg" alt="" /></li>
                                <li><img src="images/engineer-sml-01.jpg" alt="" /></li>
                                <li><img src="images/engineer-sml-03.jpg" alt="" /></li>
                            </ul>    	
                        </div>
                        <div id="tab4" className="tab-pane fade">
                            <ul>
                                <li><img src="images/engineer-sml-03.jpg" alt="" /></li>
                                <li><img src="images/engineer-sml-01.jpg" alt="" /></li>
                                <li><img src="images/engineer-sml-02.jpg" alt="" /></li>
                            </ul>    	
                        </div>
                    </div>
                </div>            
            </div>
            <div className="model-img">
                <img src="images/alberto-large.png" alt="" />
            </div>
        </div>
        )
    }
}
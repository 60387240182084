import * as React from "react";

export default class Bottom extends React.Component{
    render(){
        return(
            <section>
            <div className="container full">
                <div className="row">
                    <div className="bottom-info">
                        <div className="col-md-5">
                            <div className="info-box">
                                <img src="images/alberto-footer.jpg" alt="" />
                            </div>
                        </div>
                        <div className="col-md-7">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="info-box">
                                    <img src="images/bottom-info02.jpg" alt="" />	
                                    <div className="info-overlay">
                                        <i className="ti-location-pin"></i>
                                        <p>Webinar times are shown in US Central Time</p>
                                    </div>
                                </div>	
                            </div>
                            <div className="col-md-6">
                                <div className="info-box">
                                    <img src="images/bottom-info03.jpg" alt="" />	
                                    <div className="info-overlay">
                                        <i className="ti-location-pin"></i>
                                        <p>+12-124-2981-484 </p>
                                    </div>
                                </div>	
                            </div>
                            <div className="col-md-6">
                                <div className="info-box">
                                    <img src="images/bottom-info04.jpg" alt="" />	
                                    <div className="info-overlay">
                                        <i className="ti-location-pin"></i>
                                        <p>freelance@martinez-alberdi.com</p>
                                    </div>
                                </div>	
                            </div>
                            <div className="col-md-6">
                                <div className="info-box">
                                    <img src="images/bottom-info05.jpg" alt="" />	
                                    <div className="info-overlay">
                                        <i className="ti-location-pin"></i>
                                        <p>Simple_id Here</p>
                                    </div>
                                </div>	
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </section>
        )
    }
}
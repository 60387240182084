import * as React from "react";

export default class Header extends React.Component{
    render(){
        return(
        <header className="style1">
            <div className="topbar"> 
                <div className="logo">
                    <a href="index.html" title=""><img src="images/logo.png" alt="" /></a>
                </div>
            </div>
            <ul className="social">
                <li><a href="#" title="" className="fb"><i className="ti-facebook"></i></a></li>
                <li><a href="#" title="" className="gplus"><i className="ti-google"></i></a></li>
                <li><a href="#" title="" className="reddit"><i className="ti-reddit"></i></a></li>
                <li><a href="#" title="" className="drible"><i className="ti-dribbble"></i></a></li>
            </ul>
        </header>
        )
    }
}
import * as React from "react";

export default class Footer extends React.Component{
    render(){
        return(
            <footer>
            <div className="copyright">
                <p>Copyright © 2019 martinez-alberdi.com</p>
            </div>	
        </footer>
        )
    }
}
import React from 'react';
import './css/style.css';
import './css/responsive.css';
import $ from 'jquery';

import Header from './components/Header';
import Main from './components/Main';
import Bottom from './components/Bottom';
import Footer from './components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css'


function App() {
  return (
    <div className="layout">
      <Header/>
      <Main/>      
      <Footer/>
    </div>
  );
}

export default App;
